import React from 'react';
import CtaButtons from "../components/ctaButtons";
import Layout from "../components/layout";
import NewsletterSignup from '../components/newsletterSignup'


const NotFoundPage = () => (
  <Layout>
  <div className="site-body page-content u-textCenter">
    <br />
    <br />
    <h1>404 Not Found</h1>
    <p>
      You hit a page that doesn't exist!
    </p>
    <CtaButtons />
    <br />
    <br />
  </div>
  <NewsletterSignup />
</Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
