import React from "react";
import { Link } from "gatsby"
import styles from "./ctaButtons.module.css";

class ctaButtons extends React.Component {
  render() {
    return (
      <div className={styles.ctaButtonGroup}>
        <Link className="button button-secondary" to={"/file-a-claim/"}>File a Claim</Link>
        <Link className="button" to={"/get-insurance/"}>Get Insurance</Link>
      </div>
    );
  }
}

export default ctaButtons;